<template>
  <div :class="$style.home">
    <div
      v-for="(item, index) in data"
      :key="index"
      :class="[$style.item, value === item.id ? $style.active : null]"
      @click="onChoose(item)"
    >
      {{ item.name }}

      <div :class="$style.tag">
        <i class="ri-check-line" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    onChoose(item) {
      this.$emit('input', item.id)
      this.$emit('change')
    },
  },
}
</script>

<style lang="less" module>
.home {
  display: flex;

  .item {
    width: 100px;
    height: 46px;
    line-height: 46px;
    font-size: 13px;
    color: #5c5c5c;
    border: 1px solid #eee;
    margin-right: 10px;
    text-align: center;
    position: relative;
    cursor: pointer;

    .tag {
      display: none;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-bottom: 26px solid @primary-color;
      border-left: 26px solid transparent;

      i {
        font-size: 14px;
        color: #fff;
        position: absolute;
        left: -14px;
        bottom: -42px;
      }
    }
  }

  .active {
    border: 1px solid @primary-color;

    .tag {
      display: block;
    }
  }
}
</style>
